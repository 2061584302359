import React from "react";
import NavBar from '@Components/NavBar/NavBar';
import { CONSTANTS } from '@Constants/Constants';
import { Link } from "gatsby"

export default function UnderConstruction() {
  return (
    <>
      <NavBar logotypeColor={CONSTANTS.color.white} />
      <div className="fullscreen under-construction">
        <img className="under-construction_bg"
          src="/images/under-construction.jpg"
          srcSet="/images/under-construction-mobile.jpg 1024w, /images/under-construction.jpg 2000w"
          sizes="(max-width: 1024) 1024px, 100vw"
          loading="eager"
        />
        <div className="container">
          <h2 className="under-construction_title">
            Już wkrótce <strong>załadujemy!</strong>
          </h2>
          <p className="under-construction_subtitle">
            w tym miejscu powstaje innowacyjna platforma Nexity Store
          </p>
          <button className="btn btn--primary"><Link to={`/#chargers`}>Zawróć na stronę główną</Link></button>
        </div>
      </div>
    </>
  );
}